import React, { useEffect, useState } from "react";
import { OMSButtonStyleL2 } from "../../../../app/common/common-components/OMSButton";
import apiAgent from "../../../../app/api/apiAgent";
import FuzzySearchResultsGrid from "./FuzzySearchResultsGrid";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import tippy from "tippy.js";
import PropertyReportsStore from "../../../../app/stores/propertyReportsStore";
import { observer } from "mobx-react-lite";
import { PropertyReportModel } from "../../../../app/models/PropertyReportModel";
import { toast } from "react-toastify";

interface Props {
  propertyReportsStore: PropertyReportsStore;
}

const PropertySearchComponent: React.FC<Props> = observer(({ propertyReportsStore }) => {
  const [inputAddress, setInputAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<PropertyReportModel[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [existingReports, setExistingReports] = useState<PropertyReportModel[]>([]);

  useEffect(() => {
    apiAgent.PropertyReports.getExistingReportsForUser()
      .then((response) => {
        setExistingReports(response);
      })
      .catch(() => {
        setIsLoading(false);
        setSearchPerformed(true);
        toast.error("Some error occured. Please refresh this page.");
      });

    // Initialize Tippy.js tooltip with custom theme
    tippy(".info-tooltip", {
      content: `
        <div style="text-align: left;">
          <p>Search for property or owner details using any of the following:</p>
          <ul style="padding-left: 20px;">
            <li>Owner ID number (quickest).</li>
            <li>Property Address or part thereof.</li>
            <li>Erf number, Portion number, and Property Name. Ideal for farms who don’t always have street addresses.</li>
            <li>Unit Number Property Name. E.g. "1 Chancliff", ideal for sectional schemes.</li>
            <li>Title Deed Number or part thereof.</li>
            <li>Owner surname or part of the owner name.</li>
          </ul>
        </div>`,
      allowHTML: true,
      placement: "bottom",
      trigger: "mouseenter focus click",
      theme: "custom",
    });
    
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAddress(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const toTitleCase = (str: string) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleClick = () => {
    setIsLoading(true);
    setSearchPerformed(false);
    apiAgent.PropertyReports.fuzzySearch(inputAddress)
      .then((response) => {
        //console.log("Fuzzy search response:", response);
        const formattedResponse: PropertyReportModel[] = response.map((property: any) => ({
          propertyId: property.propertyId,
          streetAddress: property.streetAddress.split("|").map(toTitleCase).join(" "),
          propertyType: property.propertyType,
          existingReport: property.existingReport,
          reportGenerationDate: property.reportGenerationDate,
          reportId: property.reportId,
          ownerDetails: property.ownerDetails,
          ownerDetailsGenerationDate: property.ownerDetailsGenerationDate,
        }));
        //console.log("Formatted response:", formattedResponse);

        // Store the search results in MobX store
        propertyReportsStore.setSearchedPropertyReports(formattedResponse);

        setProperties(formattedResponse);
        setIsLoading(false);
        setSearchPerformed(true);
      })
      .catch((error: any) => {
        setIsLoading(false);
        setSearchPerformed(true);
        toast.error("Some error occured during search. Please try again.");
        console.log("Error during fuzzy search", error);
      });
  };

  return (
    <>
      <div>
        <div className="panel panel-default panel-property-reports oms-box">
          <div className="wrap">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12">Property and Owner search</div>
              </div>
            </div>

            <div className="panel-body" style={{ backgroundColor: "white" }}>
              <div className="col-md-12">
                <div className="form-group col-md-12" style={{ position: "relative" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      name="inputAddress"
                      className="form-control required-control text-450"
                      placeholder="Enter part of address, erf number, title deed number, ID number etc."
                      value={inputAddress}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      style={{ flex: 1 }}
                    />
                    <i className="fa fa-info-circle info-tooltip" style={{ marginLeft: "10px", color: "#337ab7", cursor: "pointer" }} aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-footer">
              <OMSButtonStyleL2
                type="button"
                style={{ marginRight: 10 }}
                ui_icon="fa-check"
                ui_type="save"
                ui_text={isLoading ? "Searching.." : "Search"}
                ui_processing_text="Searching.."
                disabled={isLoading}
                onClick={handleClick}
                processing={isLoading}
              />

              <i className="fa fa-question-circle" style={{ marginLeft: "10px", color: "#337ab7"}} aria-hidden="true"></i>
              <a href="https://help.entegral.net/en/articles/9890912-insights-overview" target="_blank" style={{ marginLeft: 5 }}>Get Started with Insights</a>
            </div>
          </div>
        </div>

        {searchPerformed && properties.length === 0 && (
          <div className="alert alert-warning" role="alert">
            No properties found.
          </div>
        )}

        {searchPerformed && properties.length > 0 && (
          <FuzzySearchResultsGrid
            //properties={properties}
            isLoading={isLoading}
            propertyReportsStore={propertyReportsStore}
          />
        )}
      </div>
    </>
  );
});

export default PropertySearchComponent;

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { DataTableComp, DataTableLoading } from "../../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../../app/api/apiAgent";
import ConfirmReportPurchaseModalPopup from "./ConfirmReportPurchaseModalPopup";
import { OMSPurchaseCredit } from "../../../../app/common/common-components/OMSPurchaseCredit";
import { toast } from "react-toastify";
import DetailModalPopup from "../../../../app/common/common-components/DetailModalPopup";
import { formatDateForPropertyReports } from "../../../../app/common/common-functions/functions";
import { ClipLoader } from "react-spinners";
import PropertyReportsStore from "../../../../app/stores/propertyReportsStore";
import { observer } from "mobx-react-lite";
import { PropertyReportModel } from "../../../../app/models/PropertyReportModel";
import { useStore } from "../../../../app/stores/store";

interface Props {
  isLoading: boolean;
  propertyReportsStore: PropertyReportsStore; // Add this line
}
const PROPERTY_REPORT_COST = 15;
const OWNER_DETAILS_COST = 5;

const CenteredSpinner: React.FC = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  }}>
    <ClipLoader size={50} color={"#ffffff"} loading={true} />
  </div>
);

const generateFileName = (streetAddress: string): string => {
  let address = streetAddress || "Unknown Address";
  if (address.length > 35) {
    address = address.substring(0, 35);
  }
  const formattedAddress = address.trim().replace(/\s+/g, " ");
  const date = new Date().toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "2-digit" }).replace(/ /g, " ");
  return `Property Insights - ${formattedAddress} - ${date}.pdf`;
};

const FuzzySearchResultsGrid: React.FC<Props> = observer(({ isLoading, propertyReportsStore }) => {
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [showOwnerDetailsModal, setShowOwnerDetailsModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<PropertyReportModel | null>(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const [selectedOwnerDetails, setSelectedOwnerDetails] = useState<{ [key: string]: any } | null>(null);
  const [loadingReportId, setLoadingReportId] = useState<string | null>(null);
  const purchaseCreditRef = useRef<OMSPurchaseCredit | null>(null);
  const dataTableRef = useRef<DataTableComp>(null);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    apiAgent.PropertyReports.getUserCreditBalance().then((response: number) => {
      const integerCreditBalance = Math.floor(response);
      setCreditBalance(integerCreditBalance);
    }).catch(() => {
      toast.error("Error fetching user credit balance.");
      });
  }, []);

  const handleReportGeneration = (property: PropertyReportModel, reportCost: number, reportType: "property" | "owner") => {
    setSelectedProperty(property);

    if (reportType === "property") {
      setShowGenerateReportModal(true);
    } else if (reportType === "owner") {
      setShowOwnerDetailsModal(true);
    }
  };

  const confirmReportGeneration = (property: PropertyReportModel, reportCost: number, reportType: "property" | "owner") => {
    setCreditBalance((prevBalance) => prevBalance - reportCost);
  
    // Set the flags to indicate generation in progress
    const updatedProperties = propertyReportsStore.searchedPropertyReports.map((prop) =>
      prop.propertyId === property.propertyId
        ? {
            ...prop,
            ...(reportType === "property" && { isReportGenerating: true }),
            ...(reportType === "owner" && { isOwnerDetailsGenerating: true }),
          }
        : prop
    );
  
    propertyReportsStore.setSearchedPropertyReports(updatedProperties);
  
    if (reportType === "property") {
      setShowGenerateReportModal(false);
      toast.info("Your report shall be available for download in the Generated Reports section after 2 minutes.");
  
      apiAgent.PropertyReports.generateFullPropertyReport(property.propertyId.toString(), property.streetAddress, property.propertyType)
        .then((response: PropertyReportModel) => {
          propertyReportsStore.updateOrAddPropertyReport({
            ...response,
            isNewReport: true,
          });
  
          // Update the flag and reportId once the report is generated
          const updatedSearchedProperties = propertyReportsStore.searchedPropertyReports.map((prop) =>
            prop.propertyId === property.propertyId
              ? {
                  ...prop,
                  reportId: response.reportId,
                  existingReport: true,
                  reportGenerationDate: response.reportGenerationDate,
                  isReportGenerating: false, // Set to false after report is generated
                }
              : prop
          );
  
          propertyReportsStore.setSearchedPropertyReports(updatedSearchedProperties);
        }).catch((error:any)=>{
          console.log("Error generating full property report", error);
          toast.error("Error generating full property report");
        });
    } else if (reportType === "owner") {
      setShowOwnerDetailsModal(false);
      toast.info("Your generated owner details shall be available in the Purchased Reports section after 1 minute.");
  
      apiAgent.PropertyReports.generateOwnerDetails(property.propertyId.toString(), property.streetAddress, property.propertyType)
        .then((ownerDetails: PropertyReportModel) => {
          propertyReportsStore.updateOrAddPropertyReport({
            ...ownerDetails,
            isNewReport: true,
          })
  
          // Update the flag and ownerDetails once the details are generated
          const updatedSearchedProperties = propertyReportsStore.searchedPropertyReports.map((prop) =>
            prop.propertyId === property.propertyId
              ? {
                  ...prop,
                  ownerDetails: ownerDetails.ownerDetails,
                  ownerDetailsGenerationDate: ownerDetails.ownerDetailsGenerationDate,
                  isOwnerDetailsGenerating: false, // Set to false after owner details are generated
                }
              : prop
          );
  
          propertyReportsStore.setSearchedPropertyReports(updatedSearchedProperties);
        }).catch((error:any)=>{
          console.log("Error generating owner report", error);
          toast.error("Error obtained while generating owner report. Please contact administrator.");
        });;
    }
  };
  
  
  

  const handleGenerateReport = (propertyId: string) => {
    const property = propertyReportsStore.searchedPropertyReports.find((prop) => prop.propertyId === propertyId);
    if (property) {
      handleReportGeneration(property, PROPERTY_REPORT_COST, "property");
    }
  };

  const handleOwnerDetails = (propertyId: string) => {
    const property = propertyReportsStore.searchedPropertyReports.find((prop) => prop.propertyId === propertyId);
    if (property) {
      handleReportGeneration(property, OWNER_DETAILS_COST, "owner");
    }
  };

  const openPurchaseCreditsModal = (userID: string) => {
    setShowGenerateReportModal(false);
    setShowOwnerDetailsModal(false);
    if (purchaseCreditRef.current) {
      purchaseCreditRef.current.onOpen(userID);
    }
  };

  const renderActions = (row: PropertyReportModel) => {
    const reportButtonText = "Property Report";
    const reportButtonClass = `btn btn-success-oms generate-report-button button-spacing`;

    const ownerDetailsButtonText = "Owner Details";
    const ownerDetailsButtonClass = `btn btn-success-oms owner-details-button`;

    return `
      <div class="button-container" style="
        display: flex;
        flex-direction: row;
        gap: 10px;
      ">
        <style>
          @media (max-width: 768px) {
            #fuzzySearchResultsTable .button-container {
              flex-direction: column !important;
            }
          }
        </style>
        <button
          class="${reportButtonClass}"
          data-id="${row.propertyId}"
          data-street-address="${row.streetAddress}"
          data-property-type="${row.propertyType}"
        >
          ${reportButtonText}
        </button>
        <button
          class="${ownerDetailsButtonClass}"
          data-id="${row.propertyId}"
          data-street-address="${row.streetAddress}"
          data-property-type="${row.propertyType}"
        >
          ${ownerDetailsButtonText}
        </button>
      </div>
    `;
  };

  const handleDownloadReport = (reportId: string) => {
    setLoadingReportId(reportId);
    const selectedReport = propertyReportsStore.searchedPropertyReports.find((report) => report.reportId === reportId);
    if (!selectedReport) {
      toast.error("Report not found!");
      setLoadingReportId(null);
      return;
    }
  
    const fileName = generateFileName(selectedReport.streetAddress);
  
    apiAgent.PropertyReports.downloadReport(reportId)
      .then((response) => {
        const binaryData: BlobPart[] = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
  
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error: unknown) => {
        console.log("Error inside listing report", error);
        toast.error("Some error occurred while downloading the report. Please try again.");
      })
      .finally(() => {
        setLoadingReportId(null);
      });
  };
  
  const handleReportDateClick = (reportId: string) => {
    handleDownloadReport(reportId);
  };

  const handleOwnerGridLinkClick = (propertyId: string) => {
    console.log("handleOwnerGridLinkClick, propertiesState: ", propertyReportsStore.searchedPropertyReports);

    try {
      const property = propertyReportsStore.searchedPropertyReports.find((prop) => prop.propertyId === propertyId);

      if (property && property.ownerDetails) {
        console.log("Raw ownerDetails:", property.ownerDetails);

        const ownerDetailsArray: Array<{ [key: string]: any }> = JSON.parse(property.ownerDetails);

        if (ownerDetailsArray.length > 0) {
          const firstDetail = ownerDetailsArray[0];

          const formatKeysInObject = (obj: { [key: string]: any }): { [key: string]: any } => {
            const formattedObj: { [key: string]: any } = {};
            Object.entries(obj).forEach(([key, value]) => {
              const formattedKey = key
                .replace(/([A-Z])/g, " $1")
                .trim()
                .replace(/^./, (str) => str.toUpperCase());
              formattedObj[formattedKey] = value;
            });
            return formattedObj;
          };

          const formattedDetail = formatKeysInObject(firstDetail);
          setSelectedOwnerDetails(formattedDetail);
          setShowOwnerDetailsModal(true);
          console.log("First Owner Detail for property ID:", propertyId, JSON.stringify(formattedDetail, null, 2));
        } else {
          console.log("Owner details array is empty for property ID:", propertyId);
        }
      } else {
        console.log("No property found with ID:", propertyId);
      }
    } catch (error) {
      console.error("Error handling owner details:", error);
    }
  };

  const columns = [
    { data: "streetAddress", title: "Street Address", className: "dt-center" },
    { data: "propertyType", title: "Property Type", className: "dt-center" },
    {
      data: null,
      title: "Purchased Reports",
      className: "dt-center",
      render: (data: any, type: any, row: PropertyReportModel) => {
        const propertyLink = row.isReportGenerating
          ? "Property (Generating...)"  // Show generating text while report is being generated
          : row.reportGenerationDate
          ? `<a href="#" class="report-download-link" data-report-id="${row.reportId}" style="text-decoration: underline;">
              Property (${new Date(row.reportGenerationDate)
                .toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
                .replace(/\//g, "-")})
            </a>`
          : "";
  
        const ownerLink = row.isOwnerDetailsGenerating
          ? "Owner (Generating...)"  // Show generating text while owner details are being generated
          : row.ownerDetailsGenerationDate
          ? `<a href="#" class="owner-grid-link" data-owner-id="${row.propertyId}" style="text-decoration: underline;">
              Owner (${new Date(row.ownerDetailsGenerationDate)
                .toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
                .replace(/\//g, "-")})
            </a>`
          : "";
  
        if (propertyLink && ownerLink) {
          return `${propertyLink}, ${ownerLink}`;
        } else if (propertyLink) {
          return propertyLink;
        } else if (ownerLink) {
          return ownerLink;
        } else {
          return "N/A";
        }
      },
    },
    {
      data: null,
      title: "Purchase",
      className: "dt-center",
      render: (data: any, type: any, row: PropertyReportModel) => renderActions(row),
    },
  ];

  useEffect(() => {
    const tableId = "#fuzzySearchResultsTable";

    $(tableId).on("click", ".generate-report-button", function () {
      const propertyId = $(this).data("id");
      handleGenerateReport(propertyId);
    });

    $(tableId).on("click", ".owner-details-button", function () {
      const propertyId = $(this).data("id");
      handleOwnerDetails(propertyId);
    });

    $(tableId).on("click", ".report-download-link", function () {
      const reportId = $(this).data("report-id");
      handleReportDateClick(reportId);
    });

    $(tableId).on("click", ".owner-grid-link", function () {
      const propertyId = $(this).data("owner-id");
      handleOwnerGridLinkClick(propertyId);
    });

    return () => {
      $(tableId).off("click", ".generate-report-button");
      $(tableId).off("click", ".owner-details-button");
      $(tableId).off("click", ".report-download-link");
      $(tableId).off("click", ".owner-grid-link");
    };
  }, [propertyReportsStore.searchedPropertyReports, loadingReportId]);

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.reloadTableData(propertyReportsStore.searchedPropertyReports);
    }
  }, [propertyReportsStore.searchedPropertyReports, loadingReportId]);

  const handleCloseOwnerDetailsModal = () => {
    setShowOwnerDetailsModal(false);
    setSelectedOwnerDetails(null);
  };

  return (
    <div className="panel-body table-responsive left-align">
      <DataTableComp
        ref={dataTableRef}
        id="fuzzySearchResultsTable"
        data={propertyReportsStore.searchedPropertyReports}
        columns={columns}
        columnDefs={[]}
        //order={[[2, "desc"]]}
        showSearchBox={false}
      />
      <DataTableLoading loading={isLoading} />
      {selectedProperty && (
        <ConfirmReportPurchaseModalPopup
          showModal={showGenerateReportModal}
          title="Generate Property Report"
          creditBalance={creditBalance}
          reportCost={PROPERTY_REPORT_COST}
          closeModalPopup={() => setShowGenerateReportModal(false)}
          confirmModalPopup={() => confirmReportGeneration(selectedProperty, PROPERTY_REPORT_COST, "property")}
          openPurchaseCreditsModal={() => openPurchaseCreditsModal(userSession?.userId || '')}
        />
      )}

      {selectedProperty && (
        <ConfirmReportPurchaseModalPopup
          showModal={showOwnerDetailsModal}
          title="Generate Owner Details"
          creditBalance={creditBalance}
          reportCost={OWNER_DETAILS_COST}
          closeModalPopup={() => setShowOwnerDetailsModal(false)}
          confirmModalPopup={() => confirmReportGeneration(selectedProperty, OWNER_DETAILS_COST, "owner")}
          openPurchaseCreditsModal={() => openPurchaseCreditsModal(userSession?.userId || '')}
        />
      )}

      <DetailModalPopup show={!!selectedOwnerDetails} onClose={handleCloseOwnerDetailsModal} title="Owner Details" data={selectedOwnerDetails} />

      <OMSPurchaseCredit ref={purchaseCreditRef} ID="SendMessageUC" />

      {loadingReportId && <CenteredSpinner />}
    </div>
  );
});

export default FuzzySearchResultsGrid;

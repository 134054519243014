import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import { useStore } from "../../../../app/stores/store";
import TaskModel from "../../common/TaskModel";
import ConfirmationModalPopup from "../../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import apiAgent from "../../../../app/api/apiAgent";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useClickButtonOutside } from "../../../../app/common/hooks/useClickButtonOutside";

const DealTaskStepView = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getData() {
      var entity = taskData;
      return entity;
    },
  }));

  const taskModelRef = useRef();

  const refTaskStatusDropdown = useRef(null);
  const taskNoteRef = useRef();

  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    };
  };

  const [tasksStageDD, setTasksStageDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [assignedRolesDD, setAssignedRolesDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [taskData, setTaskData] = useState([]);
  //const [taskStatusOpen, setTaskStatusOpen] = useState(false);

  const [taskStatusIndex, setTaskStatusIndex] = useState();
  const [stageExpected, setStageExpected] = useState(0);
  const [stageIndex, setStageIndex] = useState(null);
  const [currentTaskStatus, setCurrentTaskStatus] = useState();
  const [taskUpdateModal, setTaskUpdateModal] = useState(false);
  const [currentTask, setCurrentTask] = useState();
  const [currentStage, setCurrentStage] = useState();
  const [currentStageId, setCurrentStageId] = useState("");
  const [taskDropdownOpen, setTaskDropdownOpen] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps()
  );
  const [dealNote, setDealNote] = useState("");
  const refButton = useRef();

  const {
    userStore: { userSession },
  } = useStore();

  const onStageExpectedModelOpen = (mStageExpected, mStageIndex) => {
    setStageIndex(mStageIndex);
    setStageExpected(
      `${mStageExpected.split("-")[2]}-${mStageExpected.split("-")[1]}-${mStageExpected.split("-")[0]
      }`
    );
    window.$("#task_expected_model").modal("show");
  };

  const handleClickOutside = (event) => {
    setTaskDropdownOpen(false);
  };

  const clicked = useClickButtonOutside(refButton, handleClickOutside);

  const onStageExpectedSave = () => {
    const newData = [...taskData];
    if (stageIndex !== null) {
      newData[stageIndex].stageExpected = `${stageExpected.split("-")[2]}-${stageExpected.split("-")[1]
        }-${stageExpected.split("-")[0]}`;
      setTaskData(newData);
    }
    window.$("#task_expected_model").modal("hide");
  };

  const onNewNoteModelOpen = () => {
    taskNoteRef.current.value = "";
    window.$("#task_note_model").modal("show");
  };
  const onNewTaskModelOpen = () => {
    taskModelRef.current.onModelClick(tasksStageDD[0], {
      taskId: "",
      taskName: "",
      taskDueDate: "",
      status: 1,
      description: "",
      comment: "",
      comments: [],
      assignedRoles: [],
      dealId: props.dealID,
      dealName: props.dealName,
      statusUpdatedOn: "",
      stageExpected: "",
      taskCompletedDate: "",
      status: 1,
      description: "",
      taskDueDate: "",
      stageDD: tasksStageDD,
      taskIndex: 0,
      emailNotifiedRoles: [],
    });
  };

  const onUpdateTaskModelOpen = (stage, curTask) => {
    if (taskModelRef.current && typeof taskModelRef.current.onModelClick === 'function') {
      taskModelRef.current.onModelClick(stage, curTask);
    } else {
      console.error('taskModelRef.current or onModelClick method is not available');
    }
  };

  const onTaskSave = (
    taskModel,
    isNewTask,
    preStageId,
    curStageId,
    documentModel
  ) => {
    var mTaskData = [...taskData];
    if (isNewTask) {
      mTaskData = mTaskData.map((stage) => {
        if (stage.stageId === taskModel.stageId) {
          stage.stageTasks.push(taskModel);
        }
        return stage;
      });
    } else {
      if (preStageId == curStageId) {
        const mStageIndex = mTaskData.findIndex((e) => e.stageId == curStageId);
        if (mStageIndex != -1) {
          const mTaskIndex = mTaskData[mStageIndex].stageTasks.findIndex(
            (ele) => ele.taskId === taskModel.taskId
          );
          if (mTaskIndex != -1) {
            mTaskData[mStageIndex].stageTasks.splice(mTaskIndex, 1);
            mTaskData[mStageIndex].stageTasks.splice(mTaskIndex, 0, taskModel);
          }
        }
      } else {
        const mPreStageIndex = mTaskData.findIndex(
          (e) => e.stageId == preStageId
        );
        if (mPreStageIndex != -1) {
          const mTaskIndex = mTaskData[mPreStageIndex].stageTasks.findIndex(
            (ele) => ele.taskId === taskModel.taskId
          );
          if (mTaskIndex != -1) {
            mTaskData[mPreStageIndex].stageTasks.splice(mTaskIndex, 1);
          }
        }

        const mStageIndex = mTaskData.findIndex((e) => e.stageId == curStageId);
        if (mStageIndex != -1) {
          mTaskData[mStageIndex].stageTasks.push(taskModel);
        }
      }
    }
    setTaskData(mTaskData);
    props.onDataChange(mTaskData, documentModel);
  };

  const handleTaskStatusDD = (mStageId, mTaskModel, mStatus) => {
    setTaskStatusIndex(null);
    if (mStatus !== currentTaskStatus) {
      const mStageIndex = taskData.findIndex((e) => e.stageId == mStageId);
      if (mStageIndex == -1) return;

      let mTaskIndex = taskData[mStageIndex].stageTasks.findIndex(
        (e) => e.taskId === mTaskModel.taskId
      );

      var mTaskRequestModel = { ...mTaskModel };
      mTaskRequestModel.status = mStatus;

      if (mStatus == 5) {
        // Mark as Deleted
        var md = {
          showModal: true,
          isConfirmed: (result) => {
            if (result) {
              setConfirmationModalProps(defaultConfirmationModalProps());
              excuteTaskSaveAjax(mStageId, mTaskIndex, mTaskRequestModel);
            } else {
              setConfirmationModalProps(defaultConfirmationModalProps());
            }
          },
          title: "Delete Task",
          body:
            "Are you sure you want to delete this Task? This cannot be undone.",
        };
        setConfirmationModalProps(md);
      } else {
        setCurrentTaskStatus(mStatus);

        let commentSmgValue = getTaskMilestoneStatusDD(mStatus).find(
          (curStatus) => {
            return curStatus.value == mStatus;
          }
        );
        let commentSmg =
          userSession?.displayName +
          " marked this task as " +
          commentSmgValue.text;
        let eleComment = {
          commentID: "",
          userID: props?.userID,
          comment: commentSmg,
          isStatusChanged: false,
          createdOn: displayDateAndTime(),
          updatedOn: "",
          displayName: userSession?.displayName,
          fileID: "",
          fileName: "",
          documentName: "",
          url: "",
          size: 0,
          displaySize: "",
        };
        if (!mTaskRequestModel.comments) {
          mTaskRequestModel.comments = [];
        }
        mTaskRequestModel.comments.push(eleComment);
        excuteTaskSaveAjax(mStageId, mTaskIndex, mTaskRequestModel);
        //  window.$("#task_status_model").modal("hide");
      }
    }
  };

  const excuteTaskSaveAjax = (mStageId, mTaskIndex, mTaskModel) => {
    var mTaskData = [...taskData];
    const mStageIndex = mTaskData.findIndex((e) => e.stageId == mStageId);
    apiAgent.Deal.saveTaskAjax(mTaskModel)
      .then((res) => {
        var responseTaskModel = res.taskModel;
        var responseDoucmentModel = res.documentModel;

        if (mTaskModel.status == 5) {
          toast.info("Task deleted successfully.");
          mTaskData[mStageIndex].stageTasks.splice(mTaskIndex, 1);
          window.$("#task_status_model").modal("hide");
        } else {
          toast.info("Task updated successfully.");
          mTaskData[mStageIndex].stageTasks.splice(mTaskIndex, 1);
          mTaskData[mStageIndex].stageTasks.splice(
            mTaskIndex,
            0,
            responseTaskModel
          );
        }
        setTaskData(mTaskData);
        props.onDataChange(mTaskData);
      })
      .catch((err) => {
        console.log(err);
        toast.info("Something Went Wrong");
      });
  };

  const onTaskArrowUpClick = (stageId, task, taskIDX) => {
    let newTaskData = [...taskData];
    let stageIdx = newTaskData.findIndex((curStage, i) => {
      return curStage.stageId == stageId;
    });
    newTaskData[stageIdx].stageTasks.splice(taskIDX, 1);
    newTaskData[stageIdx].stageTasks.splice(taskIDX - 1, 0, task);
    setTaskData(newTaskData);
  };
  const onTaskArrowDownClick = (stageId, task, taskIDX) => {
    let newTaskData = [...taskData];
    let stageIdx = newTaskData.findIndex((curStage, i) => {
      return curStage.stageId == stageId;
    });
    newTaskData[stageIdx].stageTasks.splice(taskIDX, 1);
    newTaskData[stageIdx].stageTasks.splice(taskIDX + 1, 0, task);
    setTaskData(newTaskData);
  };
  const dateFormater = (inputDateStr) => {
    if (!inputDateStr)
      return "";

    if (!/^\d{2}-\d{2}-\d{4}$/.test(inputDateStr)) {
      return "";
    }

    const parts = inputDateStr.split("-");
    if (parts.length != 3)
      return "";

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return "";
    }

    if (month < 1 || month > 12) {
      return "";
    }

    const inputDate = new Date(year, month - 1, day);
    if (inputDate.getDate() !== day || inputDate.getMonth() !== month - 1 || inputDate.getFullYear() !== year)
      return "";

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfMonth = inputDate.getDate();
    const abbreviatedMonth = monthNames[inputDate.getMonth()];
    const fullYear = inputDate.getFullYear();

    const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth} ${fullYear}`;
    return formattedDateStr;
  };


  const dateFormater2 = (inputDateStr) => {

    if (!inputDateStr)
      return "";

    if (!/^\d{4}-\d{2}-\d{2}$/.test(inputDateStr)) {
      return "";
    }

    const parts = inputDateStr.split("-");
    if (parts.length != 3)
      return "";

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);



    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return "";
    }

    if (month < 1 || month > 12) {
      return "";
    }


    const inputDate = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dayOfMonth = inputDate.getDate();
    const abbreviatedMonth = monthNames[inputDate.getMonth()];
    const fullYear = inputDate.getFullYear();
    const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth} ${fullYear}`;
    return formattedDateStr;
  };

  const displayDate = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const now = new Date();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();
    const formattedDateAndTime = `${day} ${month} ${year}`;
    return formattedDateAndTime;
  };
  const displayDateAndTime = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const now = new Date();

    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    let hours = now.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateAndTime = `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;

    return formattedDateAndTime;
  };
  const getTaskMilestoneStatusDD = (value) => {
    return [
      {
        text: "Not Started",
        value: 1,
      },
      {
        text: value == 2 ? "In Progress" : "Mark As In Progress",
        value: 2,
      },
      {
        text: value == 3 ? "Issue" : "Mark As Issue",
        value: 3,
      },
      {
        text: value == 4 ? "Completed" : "Mark As Completed",
        value: 4,
      },
      {
        text: "Delete Task",
        value: 5,
      },
    ];
  };
  const getTaskStatus = (value) => {
    return [
      value == 1 || value == 0
        ? {
          text: "Not Started",
          isCurrent: true,
          icon: "fa fa-check-circle",
          className: "not-stated task-list",
        }
        : {
          text: "Not Started",
          isCurrent: false,
          icon: "fa fa-check-circle",
          className: "not-stated task-list",
        },
      value == 2
        ? {
          text: "In Progress",
          isCurrent: true,
          icon: "fa fa-clock-o",
          className: "task-process task-list",
        }
        : {
          text: "In Progress",
          isCurrent: false,
          icon: "fa fa-clock-o",
          className: "task-process task-list",
        },
      value == 3
        ? {
          text: "Issue",
          isCurrent: true,
          icon: "fa fa-exclamation-circle",
          className: "task-issue task-list",
        }
        : {
          text: "Issue",
          isCurrent: false,
          icon: "fa fa-exclamation-circle",
          className: "task-issue task-list",
        },
      value == 4
        ? {
          text: "Completed",
          isCurrent: true,
          icon: "fa fa-check-circle",
          className: "task-completed task-list",
        }
        : {
          text: "Completed",
          isCurrent: false,
          icon: "fa fa-check-circle",
          className: "task-completed task-list",
        },
      value == 5
        ? {
          text: "Delete Task",
          isCurrent: true,
          icon: "fa fa-trash",
          className: "task-list",
        }
        : {
          text: "Delete Task",
          isCurrent: false,
          icon: "fa fa-trash",
          className: "task-list",
        },
    ];
  };
  const taskLists = getTaskStatus(currentTaskStatus);

  useEffect(() => {
    setDealNote(props.dealNote);
  }, []);
  useEffect(() => {
    setTaskData(props.data);
    let filteredTasks = {};
    let filterCurrentStage = {};

    if (Array.isArray(taskData)) {
      taskData.some((task) => {
        let taskResult = task.stageTasks.find(
          (taskItem) => taskItem.taskId === props.taskId
        );
        if (taskResult) {
          filteredTasks = taskResult;
          filterCurrentStage = task;
          return true;
        }
        return false;
      });
    }

    if (props.taskId) {
      const delay = 500;
      const timer = setTimeout(() => {
        onUpdateTaskModelOpen(filterCurrentStage, filteredTasks);
      }, delay);
      return () => clearTimeout(timer);
    }

    if (props.data) {
      const stages = props.data?.map((curEle) => {
        return { value: curEle.stageId, text: curEle.stageName };
      });
      setTasksStageDD(stages);
    }
    if (props.assignedRolesDD != 0) {
      const assignedRoles = props.assignedRolesDD?.map((curElem) => {
        return { text: curElem, value: curElem };
      });
      setAssignedRolesDD(assignedRoles);
    }
    setDealNote(props.dealNote);
  }, [props]);

  const handleStatusChange = (status, currentTask, currentStage) => {
    setCurrentTaskStatus(status);
    setCurrentTask(currentTask);
    setCurrentStage(currentStage);
    window.$("#task_status_model").modal("show");
  };
  const handleTaskStatusChanged = (index, status, stageId) => {
    setTaskStatusIndex(index);
    setCurrentTaskStatus(status);
    setCurrentStageId(stageId);
    setTaskDropdownOpen(true);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId !== source.droppableId) {
      toast.info("You can't drop task outside of Stage");
      return;
    }
    const draggedTask = taskData
      .find((stage) => stage.stageName === source.droppableId)
      .stageTasks.find((curTask) => curTask.taskId === draggableId);
    const updatedData = [...taskData];

    const stageIndex = updatedData.findIndex(
      (stage) => stage.stageName === source.droppableId
    );

    // const sourceIndex = updatedData.findIndex((stage) => stage.stageName === source.droppableId);
    // const destinationIndex = updatedData.findIndex((stage) => stage.stageName === destination.droppableId);

    updatedData[stageIndex].stageTasks.splice(source.index, 1);
    updatedData[stageIndex].stageTasks.splice(
      destination.index,
      0,
      draggedTask
    );
    setTaskData(updatedData);
  };

  const getRoleBg = (tag) => {
    const tagOBJ = props.allTags?.find((curTag) => {
      return curTag.name == tag;
    });
    if (tagOBJ) {
      return tagOBJ.color;
    }
    return "#4d4d4d";
  };
  const onTaskNoteSave = (isDeleted = false) => {
    if (isDeleted) {
      // Mark as Deleted
      var confirmNoteModel = {
        showModal: true,
        isConfirmed: (result) => {
          if (result) {
            setConfirmationModalProps(defaultConfirmationModalProps());
            saveNote(isDeleted);
          } else {
            setConfirmationModalProps(defaultConfirmationModalProps());
          }
        },
        title: "Delete Note",
        body:
          "Are you sure you want to delete this Note? This cannot be undone.",
      };
      setConfirmationModalProps(confirmNoteModel);
    } else {
      setConfirmationModalProps(defaultConfirmationModalProps());
      saveNote(isDeleted);
    }
  };
  const saveNote = (isDeleted) => {
    let dealId = props.dealID;
    let note = isDeleted ? dealNote : taskNoteRef.current.value;

    note = note.replace(/(?:\r\n|\r|\n)/g, "<br />");
    if (note !== "") {
      apiAgent.Deal.saveDealNoteAjax(dealId, note, isDeleted)
        .then((res) => {
          if (res.status === "success") {
            if (isDeleted) {
              toast.info("Deal note deleted successfully");
              setDealNote("");
              props.onChangeDealNote("");
            } else {
              toast.info("Deal note saved successfully");
              setDealNote(note);
              window.$("#task_note_model").modal("hide");
              props.onChangeDealNote(note);
            }
          }
        })
        .catch((error) => {
          console.error("Error saving deal note:", error);
        });
    } else {
      toast.error("Please enter deal note");

      //taskNoteRef.current.value = dealNote;
    }
  };
  const onEditNote = () => {
    window.$("#task_note_model").modal("show");
    let note = dealNote;
    note = note.replace(/<br\s*\/?>/gi, "\n");
    taskNoteRef.current.value = note;
  };
  const getTaskDropdownClass = (value) => {
    switch (value) {
      case 0:
        return "fa fa-check-circle not-stated";
      case 1:
        return "fa fa-check-circle not-stated";
      case 2:
        return "fa fa-clock-o task-process";
      case 3:
        return "fa fa-exclamation-circle task-issue";
      case 4:
        return "fa fa-check-circle task-completed";
    }
  };
  const getArrowClass = (isDownArrow) => {
    return isDownArrow && taskDropdownOpen ? " up " : " down ";
  };

  return (
    <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
      <div>
        <div className="task_view_tab">
          <div className="pull-right btn-right">
            {props.accessType == 1 && (
              <>
                {props.actions.indexOf("save") != -1 ? (
                  <>
                    {dealNote === "" && (
                      <button
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                        }}
                        className="btn btn-success-oms mr-1"
                        onClick={() => onNewNoteModelOpen()}
                      >
                        New Note
                      </button>
                    )}

                    <button
                      className="btn btn-success-oms"
                      onClick={() => onNewTaskModelOpen()}
                    >
                      New Task
                    </button>
                  </>
                ) : null}
              </>
            )}
          </div>
          <br />
          <div className="tasks">
            {dealNote !== "" && (
              <div className="note-container">
                <div className="task-note_data bg-note mt-3">
                  <div dangerouslySetInnerHTML={{ __html: dealNote }}></div>
                  {props.actions.indexOf("save") != -1 && (
                    <div className="dropdown note-dropdown">
                      <a
                        className="btn btn-secondary note-dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-h"
                          style={{
                            color: "black",
                            fontSize: "17px",
                          }}
                        ></i>
                      </a>
                      <div
                        className="dropdown-menu note-dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <div className="note_actions">
                          <a
                            className="model_link mx-1"
                            onClick={() => {
                              onEditNote();
                            }}
                          >
                            Edit
                          </a>
                          <a
                            className="model_link mx-1"
                            onClick={() => {
                              onTaskNoteSave(true);
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {taskData?.map((curStage, i) => {
              let minHeightOfCurrentStage = null;
              if (props.accessType == 1) {
                minHeightOfCurrentStage = curStage.stageTasks?.length * 70 + 65;
              } else if (props.accessType == 0) {
                const curUserTasks = curStage.stageTasks?.filter(
                  (curTask, i) => {
                    let userIDs = [];
                    curTask.assignedRoles.map((curRole) => {
                      if (curRole.name.length > 0) {
                        curRole.name.map((curU) => {
                          if (curU.value) {
                            userIDs.push(curU.value);
                          }
                        });
                      }
                    });

                    return userIDs.includes(userSession.userId);
                  }
                );
                minHeightOfCurrentStage = curUserTasks?.length * 70 + 65;
              }

              return (
                <Droppable key={i} droppableId={curStage.stageName}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        minHeight: minHeightOfCurrentStage
                          ? `${minHeightOfCurrentStage}px`
                          : "",
                      }}
                    >
                      <div className="">
                        <h5
                          style={{ marginTop: 25 }}
                          onClick={() =>
                            onStageExpectedModelOpen(curStage.stageExpected, i)
                          }
                        >
                          {curStage.stageName == "No Milestone" &&
                            curStage.stageTasks.length == 0 ? (
                            ""
                          ) : (
                            <>
                              <b>{curStage.stageName}</b>
                              {curStage.stageExpected ? " expected " : ""}
                              <b style={{ marginRight: "5px" }}>
                                {curStage.stageExpected
                                  ? dateFormater(curStage.stageExpected)
                                  : ""}
                              </b>
                              {props.accessType == 1 &&
                                curStage.stageName != "No Milestone" && (
                                  <i
                                    style={{ cursor: "pointer" }}
                                    className="fa fa-solid fa-edit fa-deal-edit-icon"
                                  ></i>
                                )}
                            </>
                          )}
                        </h5>
                      </div>
                      {curStage.stageTasks?.map((curTask, i) => {
                        let userIDs = [];
                        curTask.assignedRoles.map((curRole) => {
                          if (curRole.name.length > 0) {
                            curRole.name.map((curU) => {
                              if (curU.value) {
                                userIDs.push(curU.value);
                              }
                            });
                          }
                        });
                        if (props.accessType != 1) {
                          if (userIDs.includes(userSession.userId)) {
                            return (
                              <Draggable
                                key={curTask.taskId}
                                draggableId={curTask.taskId}
                                index={i}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    key={i}
                                    className="task mt-1 bgcolor1 p-2"
                                  >
                                    <div className="task-title">
                                      {/* <i
                                        className="fa fa-bars bar-stripe"
                                        aria-hidden="true"
                                      ></i> */}
                                      <i
                                        style={{ cursor: "not-allowed" }}
                                        className={
                                          getTaskDropdownClass(curTask.status) +
                                          " task-list dropdown-list"
                                        }
                                        aria-hidden="true"
                                      ></i>

                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          onUpdateTaskModelOpen(
                                            curStage,
                                            curTask
                                          )
                                        }
                                        onTouchStart={() =>
                                          onUpdateTaskModelOpen(
                                            curStage,
                                            curTask
                                          )
                                        }
                                      >
                                        {curTask.taskName}
                                      </span>
                                      <span>
                                        {curTask.comments.length > 0 && (
                                          <span className="mx-2">
                                            {/* <i className="fa fa-solid fa-align-left bg-dark color-white p-icon"></i> */}
                                            <span className="coment_circle mx-1">
                                              {curTask.comments.length}
                                            </span>
                                          </span>
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          onUpdateTaskModelOpen(
                                            curStage,
                                            curTask
                                          )
                                        }
                                        onTouchStart={() =>
                                          onUpdateTaskModelOpen(
                                            curStage,
                                            curTask
                                          )
                                        }
                                      >
                                        {curTask.status == 4 ? (
                                          <div className="task-due-date-comment-box">
                                            <span>
                                              <strong>
                                                Completed on{" "}
                                                {dateFormater(
                                                  curTask.taskCompletedDate
                                                )}
                                              </strong>
                                            </span>
                                          </div>
                                        ) : curTask.taskDueDate ? (
                                          <div className="task-due-date-comment-box">
                                            <span>
                                              <strong>
                                                Due on{" "}
                                                {dateFormater2(
                                                  curTask.taskDueDate
                                                )}
                                              </strong>
                                            </span>
                                          </div>
                                        ) : null}
                                      </span>
                                    </div>

                                    <div className="tags-container">
                                      {curTask.status != 4 &&
                                        curTask.assignedRoles?.map(
                                          (curRole, i) => {
                                            return (
                                              <span
                                                key={i}
                                                className={`tag1_sm`}
                                                style={{
                                                  background:
                                                    getRoleBg(
                                                      curRole.assignedRole
                                                    ) || "",
                                                  marginRight: 5,
                                                }}
                                              >
                                                {`${curRole.assignedRole}${curRole.name &&
                                                  curRole.name?.length > 0
                                                  ? ":"
                                                  : ""
                                                  } ${curRole.name?.map(
                                                    (curEle) => {
                                                      return " " + curEle.text;
                                                    }
                                                  )}`}
                                              </span>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        } else {
                          return (
                            <Draggable
                              key={curTask.taskId}
                              draggableId={curTask.taskId}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  key={i}
                                  className="task mt-1 bgcolor1 p-2"
                                >
                                  <div className="task-title">
                                    <>
                                      <div className="d-flex-row">
                                        <div
                                          onClick={() =>
                                            handleTaskStatusChanged(
                                              i,
                                              curTask.status,
                                              curTask.stageId
                                            )
                                          }
                                          onTouchStart={() =>
                                            handleTaskStatusChanged(
                                              i,
                                              curTask.status,
                                              curTask.stageId
                                            )
                                          }
                                          className="task-status-container dropdown-list"
                                        >
                                          <i
                                            ref={refButton}
                                            onClick={() =>
                                              handleTaskStatusChanged(
                                                i,
                                                curTask.status,
                                                curTask.stageId
                                              )
                                            }
                                            onTouchStart={() =>
                                              handleTaskStatusChanged(
                                                i,
                                                curTask.status,
                                                curTask.stageId
                                              )
                                            }
                                            className={
                                              getTaskDropdownClass(
                                                curTask.status
                                              ) + " task-list"
                                            }
                                            aria-hidden="true"
                                          ></i>
                                          <div
                                            className={
                                              getArrowClass(
                                                i == taskStatusIndex &&
                                                curStage.stageId ===
                                                currentStageId
                                              ) + "dropdown-list"
                                            }
                                            ref={refButton}
                                            onClick={() =>
                                              handleTaskStatusChanged(
                                                i,
                                                curTask.status,
                                                curTask.stageId
                                              )
                                            }
                                            onTouchStart={() =>
                                              handleTaskStatusChanged(
                                                i,
                                                curTask.status,
                                                curTask.stageId
                                              )
                                            }
                                          ></div>
                                        </div>
                                        <div className="ml-15 d-flex-column">
                                          <div className="d-flex-row task-wrap">
                                            <div className="task-titles">
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  onUpdateTaskModelOpen(
                                                    curStage,
                                                    curTask
                                                  )
                                                }
                                                onTouchStart={() =>
                                                  onUpdateTaskModelOpen(
                                                    curStage,
                                                    curTask
                                                  )
                                                }
                                              >
                                                {curTask.taskName}
                                              </span>
                                              <span>
                                                {curTask.comments.length >
                                                  0 && (
                                                    <span className="mx-2">
                                                      {/* <i className="fa fa-solid fa-align-left bg-dark color-white p-icon"></i> */}
                                                      <span className="coment_circle mx-1">
                                                        {curTask.comments.length}
                                                      </span>
                                                    </span>
                                                  )}
                                              </span>
                                            </div>
                                            {curTask.status == 4 ? (
                                              <div className="task-due-date-comment-box">
                                                <span
                                                  onClick={() =>
                                                    onUpdateTaskModelOpen(
                                                      curStage,
                                                      curTask
                                                    )
                                                  }
                                                  onTouchStart={() =>
                                                    onUpdateTaskModelOpen(
                                                      curStage,
                                                      curTask
                                                    )
                                                  }
                                                >
                                                  <strong>
                                                    Completed on{" "}
                                                    {dateFormater(
                                                      curTask.taskCompletedDate
                                                    )}
                                                  </strong>
                                                </span>
                                              </div>
                                            ) : curTask.taskDueDate ? (
                                              <div className="task-due-date-comment-box">
                                                <span
                                                  onClick={() =>
                                                    onUpdateTaskModelOpen(
                                                      curStage,
                                                      curTask
                                                    )
                                                  }
                                                  onTouchStart={() =>
                                                    onUpdateTaskModelOpen(
                                                      curStage,
                                                      curTask
                                                    )
                                                  }
                                                >
                                                  <strong>
                                                    Due on{" "}
                                                    {dateFormater2(
                                                      curTask.taskDueDate
                                                    )}
                                                  </strong>
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>
                                          {curTask.status != 4 ? (
                                            <div className="tags-container">
                                              <div className="mt-1">
                                                {curTask.assignedRoles?.map(
                                                  (curRole, i) => {
                                                    return (
                                                      <span
                                                        key={i}
                                                        className={`tag1_sm`}
                                                        style={{
                                                          background:
                                                            getRoleBg(
                                                              curRole.assignedRole
                                                            ) || "",
                                                          marginRight: 5,
                                                        }}
                                                      >
                                                        {`${curRole.assignedRole
                                                          }${curRole.name &&
                                                            curRole.name?.length >
                                                            0
                                                            ? ":"
                                                            : ""
                                                          } ${curRole.name?.map(
                                                            (curEle) => {
                                                              return (
                                                                " " + curEle.text
                                                              );
                                                            }
                                                          )}`}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      {taskDropdownOpen && (
                                        <div
                                          //ref={dropdownRef}
                                          className={
                                            i == taskStatusIndex &&
                                              curStage.stageId === currentStageId
                                              ? "row"
                                              : "row d-none"
                                          }
                                          key={"drop-down-" + i}
                                        >
                                          <div className="col-md-12">
                                            <div className="dropdown-container-task">
                                              <ul className="drop-down-task">
                                                {taskLists.map((item, i) => (
                                                  <li
                                                    key={i}
                                                    className="dropdown-list"
                                                  >
                                                    <div
                                                      style={{
                                                        padding: "5px 5px",
                                                      }}
                                                      className="d-flex-row"
                                                    >
                                                      <i
                                                        className={
                                                          item.icon +
                                                          " " +
                                                          item.className
                                                        }
                                                      ></i>
                                                      <a
                                                        onClick={() => {
                                                          handleTaskStatusDD(
                                                            curStage.stageId,
                                                            curTask,
                                                            i + 1
                                                          );
                                                        }}
                                                        onTouchStart={() => {
                                                          handleTaskStatusDD(
                                                            curStage.stageId,
                                                            curTask,
                                                            i + 1
                                                          );
                                                        }}
                                                      >
                                                        {item.isCurrent ? (
                                                          <b>{item.text}</b>
                                                        ) : (
                                                          <span>
                                                            {item.text}
                                                          </span>
                                                        )}
                                                      </a>
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </div>

        {/* Models */}
        <TaskModel
          modelId="taskviewTaskModel"
          ref={taskModelRef}
          isBreadCrumb={false}
          tasksStageDD={tasksStageDD}
          contacts={props.contacts}
          assignedRolesDD={assignedRolesDD}
          onSave={onTaskSave}
          syncId={props.syncId}
          accessType={props.accessType}
          superAdmin={props.superAdmin}
          getRoleBg={getRoleBg}
        />
        <div
          className="modal fade"
          id="task_expected_model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="task_expected_model"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h3 className="modal-title" id="">
                  Update Expected Date
                </h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        value={stageExpected}
                        onChange={(e) => setStageExpected(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i> Cancel
                  </button>
                  <button
                    className="btn btn-success-oms"
                    onClick={() => onStageExpectedSave()}
                  >
                    <i className="fa fa-check"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="task_status_model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="task_status_model"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h3 className="modal-title" id="">
                  Update Task Status
                </h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className={`taskdd bg${currentTaskStatus}status d-flex-row align-items-center`}
                    >
                      <OMSDropdown
                        id={"taskDD"}
                        options={getTaskMilestoneStatusDD(currentTaskStatus)}
                        valueField="value"
                        labelField="text"
                        value={currentTaskStatus}
                        onChange={(e) =>
                          handleTaskStatusDD(
                            currentStage.stageId,
                            currentTask,
                            e.newValue
                          )
                        }
                        disabled={props.accessType == 1 ? false : true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i> Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="task_note_model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="task_note_model"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h3 className="modal-title modal-header-title" id="">
                  Deal Note
                </h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-floating">
                      <label htmlFor="taskNote" className="label-control">
                        Note
                      </label>
                      <textarea
                        ref={taskNoteRef}
                        className="form-control"
                        placeholder="Note"
                        id="taskNote"
                        style={{ height: "150px" }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i> Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success-oms"
                    // data-dismiss="modal"
                    onClick={() => {
                      onTaskNoteSave();
                    }}
                  // disabled={taskNoteRef.current.value !== "" ? false : true}
                  >
                    <i className="fa fa-check"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModalPopup {...confirmationModalProps} />
      </div>
    </DragDropContext>
  );
});

export default DealTaskStepView;
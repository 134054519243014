import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { observer, inject } from "mobx-react";
import apiAgent from "../../api/apiAgent";
import { store, useStore } from "../../stores/store";

$.typeahead = require("typeahead.js");
var Bloodhound = require("typeahead.js/dist/bloodhound.js");

const baseUrl = process.env.REACT_APP_ROOT_URL;

const OMSTwitterContactAutocomplete = ({
  id = "",
  name = "",
  placeholder = "enter name",
  style = null,
  officeid = "",
  onChange,
  onlyAgents,
  clearOnSelect
}) => {
  const { commonStore } = useStore();
  const [selected, setSelected] = useState();
  const inputRef = useRef();

  useEffect(() => {
    doTypeaheadBind();

    return () => {
      $("#" + id).typeahead("destroy");
    };
  }, []);

  const doTypeaheadBind = () => {
    if (officeid === undefined || officeid == null) officeid = "";

    const baseUrl = process.env.REACT_APP_ROOT_URL;

    const agentSource = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${baseUrl}/contacts/searchAgentAjax`,
        prepare: function (query, settings) {
          settings.headers = [];

          var token = commonStore.token;
          settings.url += `?Query=${encodeURIComponent(query)}`;
          settings.url += `&OfficeID=${officeid}`;
          settings.headers["Authorization"] = `Bearer ${token}`;
          settings.withCredentials = true;
          settings.type = "get";

          return settings;
        },
        limit: 0,
      },
    });

    const contactSource = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${baseUrl}/contacts/searchContactAjax`,
        prepare: function (query, settings) {
          settings.headers = [];

          var token = commonStore.token;
          settings.url += `?Query=${encodeURIComponent(query)}`;
          settings.url += `&OfficeID=${officeid}`;
          settings.headers["Authorization"] = `Bearer ${token}`;
          settings.withCredentials = true;
          settings.type = "get";

          return settings;
        },
        limit: 0,
      },
    });

    const typeaheadOptions = {
      name: id + "_Agents",
      display: "displayName",
      source: agentSource,
      limit:10,
      templates: {
        header: '<span class="dropdown-header">Agents</span>',
        suggestion: function (data) {
          if (data.type == 1)
            return '<span><i class="fa fa-user-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + data.displayName + "</span>";
          else if (data.type == 2)
            return '<span><i class="fa fa-user" aria-hidden="true"></i>&nbsp;&nbsp;' + data.displayName + "</span>";
          else return "";
        },
      }
    };

    const typeaheadContactOptions = {
      name: id + "_Contacts",
      display: "displayName",
      source: contactSource,
      limit: 0,
      templates: {
        header: '<span class="dropdown-header">Contacts</span>',
        suggestion: function (data) {
          if (data.type == 1)
            return '<span><i class="fa fa-user-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + data.displayName + "</span>";
          else if (data.type == 2)
            return '<span><i class="fa fa-user" aria-hidden="true"></i>&nbsp;&nbsp;' + data.displayName + "</span>";
          else return "";
        },
      }
    };

    if (onlyAgents) {
      $("#" + id).typeahead(null, typeaheadOptions);
    } else {
    $("#" + id).typeahead(null, typeaheadOptions, typeaheadContactOptions);
    }

    $("#" + id).bind("typeahead:select", function (ev, suggestion) {
      if (selected !== suggestion) {
        setSelected(suggestion);
        if (onChange !== undefined) onChange(suggestion);
        if (clearOnSelect) {
          $("#" + id).typeahead("val", "");
        }
      }
    });

  };

  return (
  <div className="dropdown">
  <input
       ref={inputRef}
       type="text"
       id={id}
       name={name}
       className="form-control"
       placeholder={placeholder}
       autoComplete="off"
     />
  </div>
  );
  };
  
  OMSTwitterContactAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  officeid: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onlyAgents: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  };
  
  export {OMSTwitterContactAutocomplete};



const OMSTwitterAutocomplete = ({
  id = '',
  name = '',
  value = '',
  Country='',
  style = null,
  onChange = undefined,
  onInfoChange = undefined,

}) => {
  const { commonStore } = useStore();
  const inputRef = useRef(null);
  const [displayText, setDisplayText] = useState(value);

  useEffect(() => {
    doTypeaheadBind();
    inputRef.current.value = value;
    $("#" + id).change((e) => {
      const displayText = $("#" + id).val();
      setDisplayText(displayText);
      if (onChange) onChange(displayText);
    });
  }, []);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

   const doTypeaheadBind = () => {

  const baseUrl = process.env.REACT_APP_ROOT_URL;
  const bestPictures = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: `${baseUrl}/settings/searchAreaAjax`,
      //url: '/Settings/SearchAreaAjax',
      prepare: function (query, settings) {
        const CountryName = document.getElementById('hdnAreaAutocompleteC').value;
        //settings.url = `/Settings/SearchAreaAjax?Query=${encodeURIComponent(query)}&Country=${encodeURIComponent(CountryName)}`;

        settings.headers = [];
          //settings.url += "?Query=" + query;

          //console.log("inside OMSTwitterAutocomplete2, query", query);
          //console.log("inside OMSTwitterAutocomplete2, CountryName", CountryName);
          settings.url += `?Query=${encodeURIComponent(query)}&Country=${encodeURIComponent(CountryName)}`;
          //console.log("inside OMSTwitterAutocomplete2, token", commonStore.token);

          var token = commonStore.token;
          //console.log("inside OMSTwitterAutocomplete2, token", token);
          settings.headers["Authorization"] = `Bearer ${token}`;

          settings.withCredentials = true;
          settings.type = "get";
          //console.log("inside OMSTwitterAutocomplete2, settings", settings);
          return settings;

        
      },
      limit: 0,
    },
  });

    $("#" + id).typeahead(null, {
      name: id,
      display: 'displayName',
      source: bestPictures,
      limit: 0,
      templates: {
        suggestion: function (data) {
          const arr = data.displayName.split(',');
          return (
            '<span><strong>' +
            arr[0] +
            '</strong><small>,' +
            arr.slice(1).join(',') +
            '</small></span>'
          );
        },
      },
    });

    $("#" + id).bind('typeahead:select', (ev, suggestion) => {
      //console.log("inside OMSTwitterAutocomplete, suggestion before calling onInfoChange", suggestion);
      setDisplayText(suggestion.displayName);
      if (onInfoChange) onInfoChange(suggestion);
    });
  };

  return (
    <div className="dropdown">
      <input
        type="hidden"
        data-id={id}
        id="hdnAreaAutocompleteC"
        name="hdnAreaAutocompleteC"
        value={Country}
      />
      <input
        type="text"
        ref={inputRef}
        id={id}
        name={name}
        className="form-control"
        autoComplete="off"
      />
    </div>
  );
};

OMSTwitterAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  Country: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onInfoChange: PropTypes.func,
};

export {OMSTwitterAutocomplete};



const OMSTwitterAutocomplete2 = observer(function OMSTwitterAutocomplete2(props) {
  const { commonStore } = useStore();

  useEffect(() => {
    //console.log("inside OMSTwitterAutocomplete2, commonStore", commonStore);
    doTypeaheadBind();
  }, []);

  const doTypeaheadBind = () => {
    const baseUrl = process.env.REACT_APP_ROOT_URL;
    var bestPictures = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${baseUrl}/settings/searchAreaCityAjax`,
        prepare: function (query, settings) {
          settings.headers = [];
          settings.url += "?Query=" + query;
          //console.log("inside OMSTwitterAutocomplete2, token", commonStore.token);

          var token = commonStore.token;
          //console.log("inside OMSTwitterAutocomplete2, token", token);
          settings.headers["Authorization"] = `Bearer ${token}`;

          settings.withCredentials = true;
          settings.type = "get";
          //console.log("inside OMSTwitterAutocomplete2, settings", settings);
          return settings;
        },
        limit: 0,
      },
    });

    $("#" + props.id).typeahead(null, {
      name: props.id,
      display: "displayName",
      source: bestPictures,
      limit: 0,
      templates: {
        suggestion: function (data) {
          //console.log("result of area search in OMSTwitterAutocomplete2", data);
          if (data.suburbID == "") return "<span><strong>" + data.town + "</strong></span>";
          else return "<span>&nbsp&nbsp;" + data.town + ", " + data.suburb + "</span>";
        },
      },
    });

    $("#" + props.id).bind("typeahead:select", function (ev, suggestion) {
      if (props.onSelect !== undefined) props.onSelect(suggestion);
    });
  };

  return (
    <div className="dropdown">
      <input type="text" id={props.id} name={props.name} className="form-control" autoComplete="off" />
    </div>
  );
});

OMSTwitterAutocomplete2.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  onSelect: PropTypes.func,
};

OMSTwitterAutocomplete2.defaultProps = {
  id: "",
  name: "",
  value: "",
  style: null,
  onSelect: undefined,
};

export { OMSTwitterAutocomplete2 };

const OMSTwitterAutocompleteAgent = observer(function OMSTwitterAutocompleteAgent(props) {
  //export class OMSTwitterAutocompleteAgent extends React.Component {
  var { commonStore } = useStore();
  const {displayName, setDisplayName} = useState("");

  useEffect(() => {
    doTypeaheadBind();
  }, []);

  const doTypeaheadBind = () => {
    //console.log("inside OMSTwitterAutocompleteAgent-doTypeaheadBind");

    var bestPictures = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${baseUrl}/contacts/searchAgentAjax?Func=Reassign`,
        prepare: function (query, settings) {
          settings.headers = [];

          var token = commonStore.token;
          
          settings.url += "&Query=" + query;
          settings.url += "&OfficeID=" + props.OfficeID
          settings.headers["Authorization"] = `Bearer ${token}`;
          settings.withCredentials = true;
          settings.type = "get";

          return settings;
        },
        limit: 0,
      },
    });

    $("#" + props.id).typeahead(null, {
      name: props.id,
      display: "displayName",
      source: bestPictures,
      limit: 0,
      templates: {
        suggestion: function (data) {
          return "<span>" + data.displayName + "</span>";
        },
      },
    });

    $("#" + props.id).bind(
      "typeahead:select",
      function (ev, suggestion) {
        //console.log("inside OMSTwitterAutocompleteAgent-selection, suggestion", suggestion);
        //this.setState({ displayName: suggestion.displayName });
        //setDisplayName(suggestion.displayName);
        //Rohit: the above statement gave error, so commented it out. No visible difference in the UI and functionality
        if (props.onChange !== undefined) props.onChange(suggestion);
      }
    );
  };

  return (
    <div className="dropdown">
      <input type="text" id={props.id} name={props.name} className="form-control" autoComplete="off" />
    </div>
  );
});

OMSTwitterAutocompleteAgent.propTypes = {
  id: PropTypes.string,
  OfficeID: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

OMSTwitterAutocompleteAgent.defaultProps = {
  id: "",
  name: "",
  value: "",
  style: null,
  onChange: undefined,
  OfficeID: ''
};

export { OMSTwitterAutocompleteAgent };

export class OMSTwitterAutocompleteSearch extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: "",
    name: "",
    value: "",
    style: null,
    onChange: undefined,
  };

  componentDidMount() {
    this.doTypeaheadBind();
  }

  componentWillReceiveProps(nextProps) {}

  doTypeaheadBind = () => {
    var bestPictures = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: "/Home/SearchQueryCloudAjax?Query=%QUERY",
        wildcard: "%QUERY",
        rateLimitBy: "debounce",
        rateLimitWait: 300,
      },
    });

    $("#" + this.props.id).typeahead(null, {
      name: this.props.id,
      display: "displayName",
      source: bestPictures,
      limit: 0,
      templates: {
        suggestion: function (data) {
          //console.log(data);
          return "<div>" + data.displayName + "</div>";
        },
      },
    });

    $("#" + this.props.id).bind(
      "typeahead:select",
      function (ev, suggestion) {
        this.setState({ displayName: suggestion.displayName });
        if (this.props.onChange !== undefined) this.props.onChange(suggestion);
      }.bind(this)
    );
  };

  render() {
    return (
      <div className="dropdown search-text">
        <input type="text" id={this.props.id} name={this.props.name} className="search-input" autoComplete="off" />
        <i className="fa fa-search" aria-hidden="true" />
      </div>
    );
  }
}

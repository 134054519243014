import React from "react";
import PropTypes from "prop-types";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DocumentFileComponent } from "./DocumentFileComponent";
import DatePicker from "react-datepicker";
import apiAgent from "../../../app/api/apiAgent";
import { useHistory } from "react-router";
import { history } from "../../..";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import { ToPriceString, FromDateStringToJavascriptDate, FromJavascriptDateToDateString } from "../../../app/common/common-functions/functions";
import { Link } from "react-router-dom";

const moment = require("moment");

export class MandateStepView extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    officeSyncID: PropTypes.string,
    officeID: PropTypes.string,
    mandateStatusChanged: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.setState({
      confirmationModalProps: {
        showModal: false,
        isConfirmed: (result) => {},
        title: "",
        body: "",
      },
      isDealLoading: false,
    });
    this.documentComponentRef = React.createRef();
  }

  getStateFromData = (data) => {
    return {
      Step: "Listing capture: Mandate",
      MandateID: data.mandateID,
      PropertyID: data.propertyID,
      Price: data.price,
      SaleType: data.saleType,
      SaleTypeUnits: data.saleTypeUnits,
      MandateType: data.mandateType == "" ? this.props.MandateTypes[0] : data.mandateType,
      Status: data.status,
      CapturedDate: data.capturedDate,
      StartDate: data.startDate,
      ExpiryDate: data.expiryDate,
      OccupationDate: data.occupationDate,
      StartDateDT: FromDateStringToJavascriptDate(data.startDate),
      ExpiryDateDT: FromDateStringToJavascriptDate(data.expiryDate),
      OccupationDateDT: FromDateStringToJavascriptDate(data.occupationDate),
      CommType: data.commType,
      CommPerc: data.commPerc,
      CommAmount: data.commAmount,
      Tax: data.tax,
      PriceOnApplication: data.mandateID == "" ? false : parseInt(data.price) == 0,
      DepositRequirement: data.depositRequirement,
      LeasePeriod: data.leasePeriod,
      LeaseEscalationPer: data.leaseEscalationPer,
      ItemisedCosts: data.itemisedCosts,
      ItemisedCostsItemDD: this.loadItemisedCostsItemDD(data.itemisedCosts),
      ItemisedUnits: "",
      Documents: data.documents,
      //activeTabID: "mandate-contract",
      activeTabID: this.state ? this.state.activeTabID : "mandate-contract",
    };
  };

  isDealsTabShow() {
    if (this.props.data.propertyID && this.props.userSession?.enabledModules.includes(1) && this.props.officeID == this.props.userSession?.officeID) {
      return true;
    }
    return false;
  }

  handleDocumentUpload = (files) => {
    this.setState((prevState) => ({
      Documents: [...prevState.Documents, ...files],
    }));
  };

  componentDidMount() {
    if (this.isDealsTabShow()) {
      this.getDealsForProperty();
    }
    var icons = {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-arrow-left",
      next: "fa fa-arrow-right",
      today: "fa fa-screenshot",
      clear: "fa fa-trash",
      close: "fa fa-remove",
    };

    // window.$('.MandateDatePicker').datetimepicker({
    //     format: 'DD-MM-YYYY',
    //     icons: icons
    // });

    // window.$('.MandateDatePicker2').datetimepicker({
    //     format: 'DD-MM-YYYY',
    //     useCurrent: false,
    //     icons: icons
    // });

    // window.$('.MandateDatePicker2').on('dp.change', function (e) {
    //     this.setState({ [e.currentTarget.name]: e.currentTarget.value });

    // }.bind(this));

    // window.$('.MandateDatePicker').on('dp.change', function (e) {
    //     this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    //     if (this.props.onDependantPropertyChange !== undefined)
    //         this.props.onDependantPropertyChange('Mandate_' + e.currentTarget.name, e.currentTarget.value);
    // }.bind(this));

    this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
  }

  getDealsForProperty = () => {
    apiAgent.Deal.dealsbypropertyid(this.props.data.propertyID).then((res) => {
      this.setState({ dealData: res });
    });
  };

  doSetFormData = async (data) => {
    await this.uploadDocuments();

    const mandateData = {
      PropertyID: this.state.PropertyID,
      MandateID: this.state.MandateID,
      Price: this.state.PriceOnApplication ? "0" : this.state.Price.replace(",", ""),
      SaleType: this.state.SaleType,
      SaleTypeUnits: this.state.SaleTypeUnits,
      MandateType: this.state.MandateType,
      Status: this.state.Status,
      CapturedDate: this.state.CapturedDate,
      StartDate: this.state.StartDate,
      ExpiryDate: this.state.ExpiryDate,
      OccupationDate: this.state.OccupationDate,
      CommType: this.state.CommType,
      CommPerc: this.state.CommPerc,
      CommAmount: this.state.CommAmount.replace(",", ""),
      Tax: this.state.Tax,
      LeasePeriod: this.state.LeasePeriod,
      LeaseEscalationPer: this.state.LeaseEscalationPer,
      DepositRequirement: this.state.DepositRequirement,

      ItemisedCosts: this.state.ItemisedCosts.map((itemisedItem, i) => {
        //this is running twice, once in camel and once in pascal case
        //console.log("inside mandateData itemisedItem, itemisedItem=", itemisedItem);
        return {
          ItemName: itemisedItem.itemName,
          SizeOrUnits: itemisedItem.sizeOrUnits,
          UnitType: itemisedItem.unitType,
          Cost: itemisedItem.cost.replace(",", ""),
        };
      }),

      Documents: this.state.Documents.map((fileItem, i) => {
        //console.log("inside mandateData fileItem, fileItem=", fileItem);
        let fileID = fileItem.fileID;
        return {
          FileID: fileItem.fileID,
          FileName: fileItem.fileName,
          Description: fileItem.description,
          Order: fileItem.order,
          IsRestrictAccess: fileItem.isRestrictAccess,
          StrIsNewFile: fileItem.isNewFile,
          FileIcon: "",
          Size: fileItem.file ? fileItem.file.size : fileItem.size,
          //...(fileItem.fileID.indexOf('NAP') != -1 ? {} : { [fileID]: fileItem.file }),
        };
      }),
    };
    let mandateJson = JSON.stringify(mandateData);
    //console.log("inside mandate-doSetFormData, mandateJson=", mandateJson);
    data.append("MandateJson", mandateJson);
  };

  doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };

    if (!this.state.MandateType) {
      result.message = "Please select mandate type";
      return result;
    } else if (!this.state.Price) {
      result.message = "Please enter price";
      return result;
    } else if (!this.state.StartDate) {
      result.message = "Please enter mandate start";
      return result;
    } else if (!this.state.ExpiryDate) {
      result.message = "Please enter mandate end";
      return result;
    }

    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(this.getStateFromData(data), function () {
      this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
      this.documentComponentRef.current.doSetState(this.state.Documents);
    });
  };

  doGetState = () => {
    return this.state;
  };

  uploadDocuments = async () => {
    // var files=this.state.Documents.map((file)=>{
    //   return file.fileID.indexOf("NAP") != -1 ;
    // });

    //console.log("inside uploadFilesAndPhotos, files=", this.state.Documents);

    let newFiles = this.state.Documents.filter((file) => file.fileID.indexOf("NAP") != -1);
    let oldFiles = this.state.Documents.filter((file) => file.fileID.indexOf("NAP") === -1);

    newFiles.forEach((file) => {
      file.isNewFile = "true";
    });
    oldFiles.forEach((file) => {
      file.isNewFile = "false";
    });

    // newFiles = newFiles.map((file) => {
    //   return {...newFiles, isNewFile: 'true'};
    // });

    // oldFiles = oldFiles.map((file) => {
    //   return {...oldFiles, isNewFile: 'false'};
    // });

    //console.log("inside uploadFilesAndPhotos, this.state.Documents=", this.state.Documents, "newFiles=", newFiles, "oldFiles=", oldFiles);

    //var photos = this.state.Photos;
    //console.log("inside uploadFilesAndPhotos, photos=", photos, "files=", files);
    //const photoNames = photos.map((photo) => photo.fileName);

    // Modify file names to replace "," and "+" with "-"
    const modifyFileName = (file) => {
      if (file.fileName.includes(",") || file.fileName.includes("+")) {
        file.fileName = file.fileName.replace(/,/g, "-").replace(/\+/g, "-");
      }
    };

    newFiles.forEach(modifyFileName);
    oldFiles.forEach(modifyFileName);

    const fileNames = newFiles.map((file) => file.fileName);
    //const allNames = [...photoNames, ...fileNames];
    let presignedUrls = [];
    //console.log("inside uploadFilesAndPhotos, fileNames=", fileNames);

    try {
      let presignedUrls;
      if (fileNames.length != 0) {
        presignedUrls = await apiAgent.S3Helper.getPresignedUrls(fileNames, this.props.officeSyncID);
        //console.log("inside uploadFilesAndPhotos, response=", presignedUrls);
        //console.log("inside uploadFilesAndPhotos, files=", newFiles);
        newFiles.forEach((file) => {
          if (file) {
            var name = file.fileName;
            var jsonString = presignedUrls[name];
            var jsonObject = JSON.parse(jsonString);
            //console.log("inside uploadFilesAndPhotos,presignedUrls=", presignedUrls, "file=", file, "jsonObject=", jsonObject);
            file.fileName = jsonObject.objectKey;
            file.fileID = jsonObject.fileID;
            //file.Size=file.File.size;
            //file.Size=

            var url = jsonObject.url;

            this.uploadFile(file, url);
          }
        });
      }

      this.setState({ Documents: [...oldFiles, ...newFiles] });
    } catch (error) {
      console.log("inside uploadFilesAndPhotos, error=", error);
    }

    //console.log("after uploadFilesAndPhotos, this.state.Documents=", this.state.Documents);
  };

  uploadFile = async (file, url) => {
    //console.log("inside uploadFile, file=", file.file, "url=", url);
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file.file,
    };

    try {
      const response = await fetch(url, options);
      //console.log("inside uploadFile, response=", response);
      return response;
    } catch (error) {
      console.error("Error", error);
    }
  };

  loadItemisedCostsItemDD = (ItemisedCosts) => {
    //console.log("inside loadItemisedCostsItemDD, ItemisedCosts=",ItemisedCosts);
    var optionsDD = [];
    optionsDD.push({ Text: "Balcony", Value: "Balcony" });
    optionsDD.push({
      Text: "Installation allowance",
      Value: "Installation allowance",
    });
    optionsDD.push({ Text: "Office", Value: "Office" });
    optionsDD.push({ Text: "Operational cost", Value: "Operational cost" });
    optionsDD.push({ Text: "Parking Basement", Value: "Parking Basement" });
    optionsDD.push({ Text: "Parking Covered", Value: "Parking Covered" });
    optionsDD.push({ Text: "Parking Open", Value: "Parking Open" });
    optionsDD.push({ Text: "Storage", Value: "Storage" });

    var selectedItems = ItemisedCosts.map(function (item) {
      //console.log("inside selected Items, item=",item);
      return item.itemName;
    });

    var itemDD = [];
    itemDD.push({ Text: "- Select -", Value: "" });

    for (var i = 0; i < optionsDD.length; i++) {
      if (selectedItems.indexOf(optionsDD[i].Value) == -1) {
        itemDD.push(optionsDD[i]);
      }
    }

    return itemDD;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleStatusChange = (e) => {
    var newStatus = e.target.value;
    if (newStatus == 1 && this.state.Status != 1 && this.state.Status != 5) {
      //Set mandate expire date to 6 months from now.

      var date = moment().add(6, "M").toDate();

      this.setState({
        ExpiryDate: FromJavascriptDateToDateString(date),
        ExpiryDateDT: date,
        Status: newStatus,
      });
    } else this.setState({ Status: newStatus });

    if (this.props.onDependantPropertyChange !== undefined) this.props.onDependantPropertyChange("Status", e.target.value);
    this.props.mandateStatusChanged(newStatus);
  };

  handleSaleTypeChange = (e) => {
    var saleType = e.target.value;
    var saleTypeUnits = 4;
    if (saleType == 2) saleTypeUnits = 3;
    else if (saleType == 1) saleTypeUnits = 4;
    this.setState({
      SaleType: saleType,
      SaleTypeUnits: saleTypeUnits,
    });

    if (this.props.onDependantPropertyChange !== undefined) this.props.onDependantPropertyChange("SaleType", saleType);
  };

  handleNumericChange = (name, value) => {
    /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        } */

    this.setState({
      [name]: value,
    });
  };

  handleDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  handlePriceNumericChange = (name, value) => {
    /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }*/
    this.calculateMandateAmounts(value, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
  };

  handleVATChange = (name, value) => {
    /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }*/
    this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, value);
  };

  handleCommTypeChange = (value) => {
    //this.setState({CommType: value});
    this.calculateMandateAmounts(this.state.Price, value, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
  };

  handleCommPercChange = (name, value) => {
    this.calculateMandateAmounts(this.state.Price, this.state.CommType, value, this.state.CommAmount, this.state.Tax);
  };

  handleCommAmountChange = (name, value) => {
    this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, value, this.state.Tax);
  };

  handlePriceOnApplicationChange = (e) => {
    if (e.target.checked) {
      this.calculateMandateAmounts("0", this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
    } else {
      this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
    }
  };

  calculateMandateAmounts = (Price, CommType, CommPerc, CommAmount, VAT) => {
    //console.log(Price);
    //console.log(CommType);
    //console.log(CommPerc);
    //console.log(CommAmount);
    //console.log(VAT);

    var PriceString = Price;
    var VATString = VAT;
    var CommPercString = CommPerc;
    var CommAmountString = CommAmount;

    var iPrice = 0;
    var iCommPerc = 0;
    var iCommAmount = 0;
    var iVAT = 0;

    Price = Price.replace(/,/g, "");
    CommAmount = CommAmount.replace(/,/g, "");

    if (!Number.isNaN(Price)) iPrice = parseFloat(Price);

    if (!Number.isNaN(CommPerc)) iCommPerc = parseFloat(CommPerc);

    if (!Number.isNaN(CommAmount)) iCommAmount = parseFloat(CommAmount);

    if (!Number.isNaN(VAT)) iVAT = parseFloat(VAT);

    var VATAmount = 0;
    var Gross = 0;
    var GrossAmount = 0;
    var OwnerNett = 0;
    var PriceOnApplication = false;

    if (iPrice == 0) PriceOnApplication = true;

    if (CommType == "1") {
      // %
      iCommAmount = (iPrice * iCommPerc) / 100;
      VATAmount = (iCommAmount * iVAT) / 100;
      Gross = iCommPerc + (iCommPerc * iVAT) / 100;
      GrossAmount = iCommAmount + VATAmount;
      OwnerNett = iPrice - GrossAmount;
      CommAmountString = iCommAmount.toFixed(2);
    } else {
      // Fixed

      if (iPrice != 0) iCommPerc = (100 * iCommAmount) / iPrice;

      VATAmount = (iCommAmount * iVAT) / 100;
      Gross = iCommPerc + (iCommPerc * iVAT) / 100;
      GrossAmount = iCommAmount + VATAmount;
      OwnerNett = iPrice - GrossAmount;
      CommPercString = iCommPerc.toFixed(2);
    }

    this.setState({
      Price: PriceString,
      PriceOnApplication: PriceOnApplication,
      CommType: CommType,
      CommPerc: CommPercString,
      CommAmount: CommAmountString,
      Tax: VATString,
      VATAmount: VATAmount.toFixed(2),
      Gross: Gross.toFixed(2),
      GrossAmount: GrossAmount.toFixed(2),
      OwnerNett: OwnerNett.toFixed(2),
    });
  };

  handleItemisedCostDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  onAddItemisedCostUnitClick = (e) => {
    if (this.state.ItemisedUnits) {
      var itemName = this.state.ItemisedUnits;
      var selectedItems = this.state.ItemisedCosts;

      var index = -1;
      for (var i = 0; i < selectedItems; i++) {
        if (selectedItems[i].itemName == itemName) {
          index = i;
          break;
        }
      }

      if (index == -1) {
        selectedItems.push({
          itemName: itemName,
          sizeOrUnits: "",
          unitType: "1",
          cost: "",
        });
        this.setState({
          ItemisedCosts: selectedItems,
          ItemisedUnits: "",
          ItemisedCostsItemDD: this.loadItemisedCostsItemDD(selectedItems),
        });
      }
    }
  };

  onRemoveItemisedCostUnitClick = (itemName) => {
    if (itemName) {
      var selectedItems = this.state.ItemisedCosts;
      //console.log("inside onRemoveItemisedCostUnitClick, selectedItems=",selectedItems);
      var index = -1;
      for (var i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].itemName == itemName) {
          index = i;
          break;
        }
      }

      if (index != -1) {
        selectedItems.splice(index, 1);
        this.setState({
          ItemisedCosts: selectedItems,
          ItemisedUnits: "",
          ItemisedCostsItemDD: this.loadItemisedCostsItemDD(selectedItems),
        });
      }
    }
  };

  handleItemisedCostSizeOrUnitChange = (name, value) => {
    name = name.replace("SizeOrUnits_", "");
    var selectedItems = this.state.ItemisedCosts;
    for (var i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].itemName == name) {
        selectedItems[i].sizeOrUnits = value;
        break;
      }
    }
    this.setState({ ItemisedCosts: selectedItems });
  };

  handleItemisedCostCostChange = (name, value) => {
    name = name.replace("Cost_", "");
    var selectedItems = this.state.ItemisedCosts;
    for (var i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].itemName == name) {
        selectedItems[i]["cost"] = value;
        break;
      }
    }
    this.setState({ ItemisedCosts: selectedItems });
  };

  handleItemisedCostUnitTypeChange = (e) => {
    var name = e.target.name.replace("UnitType_", "");
    var value = e.target.value;
    var selectedItems = this.state.ItemisedCosts;
    for (var i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].itemName == name) {
        selectedItems[i].unitType = value;
        break;
      }
    }
    this.setState({ ItemisedCosts: selectedItems });
  };

  onFileComponentChange = (files) => {
    this.setState({ Documents: files });
  };

  state = this.getStateFromData(this.props.data);

  setActiveTabId = (tabId) => {
    //console.log("inside setActiveTabId tabID=", tabId);
    this.setState({ activeTabID: tabId });
  };

  redirectToDeal = (url, propertyId) => {
    if (url == "newDeal") {
      history.push(`/deal/saveDeal?ID=&propertyID=${propertyId}`);
    } else {
      let history = useHistory();
      history.push("/" + url);
    }
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onDealModelDelete = () => {
    this.setState({ isDealLoading: true });
    apiAgent.Deal.deletedealAjax(this.state.curDealID, this.state.curUserID)
      .then((res) => {
        this.getDealsForProperty();
        setTimeout(() => {
          this.setState({ isDealLoading: false });
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          this.setState({ isDealLoading: false });
        }, 1000);
      });
  };
  onDealDelete = (item) => {
    this.setState({ curDealID: item.dealID, curUserID: item.userID });
    this.showPopupAndDeleteOnConfirmation("Delete Deal", "Are you sure, you want to delete this Deal. It can't undo", this.onDealModelDelete);
  };

  render() {
    var self = this;
    var tabContent = [
      { link: "#mandate-contract", title: "Contract" },
      { link: "#mandate-commission", title: "Commission" },
      { link: "#mandate-itemisedcost", title: "Itemised Cost" },
      { link: "#mandate-documents", title: "Documents" },
    ];
    if (this.isDealsTabShow()) {
      tabContent = [
        { link: "#mandate-contract", title: "Contract" },
        { link: "#mandate-commission", title: "Commission" },
        { link: "#mandate-itemisedcost", title: "Itemised Cost" },
        { link: "#mandate-documents", title: "Documents" },
        { link: "#mandate-deals", title: "Deals" },
      ];
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="col-md-2 col-sm-12 control-label">Listing price</label>
                <div className="col-md-4 col-sm-12">
                  <OMSNumericBox
                    id="Price"
                    name="Price"
                    separator=","
                    maxLength={12}
                    maxDecimalPoints={2}
                    value={this.state.Price}
                    isDecimal={true}
                    onChange={this.handlePriceNumericChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <span>
                    <input
                      type="checkbox"
                      id="PriceOnApplication"
                      name="PriceOnApplication"
                      defaultChecked={this.state.PriceOnApplication}
                      onChange={this.handlePriceOnApplicationChange}
                    />
                    &nbsp;Price on application
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 col-xs-12 control-label">Sale type</label>
                <div className="col-md-4 col-xs-12">
                  <select id="SaleType" name="SaleType" className="form-control" value={this.state.SaleType} onChange={this.handleSaleTypeChange}>
                    <option value="1">For sale</option>
                    <option value="2">For rent</option>
                    {/*<option value="3">Auction</option>*/}
                    <option value="4">Valuation</option>
                  </select>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div style={{ display: this.state.SaleType > 2 ? "none" : "" }}>
                    <select
                      id="SaleTypeUnits"
                      name="SaleTypeUnits"
                      style={{ width: "auto" }}
                      className="form-control"
                      value={this.state.SaleTypeUnits}
                      onChange={this.handleChange}
                    >
                      {this.state.SaleType == 2 ? <option value="1">per day</option> : null}
                      {this.state.SaleType == 2 ? <option value="2">per week</option> : null}
                      {this.state.SaleType == 2 ? <option value="3">per month</option> : null}
                      {this.state.SaleType == 2 ? <option value="6">per m² (monthly)</option> : null}
                      {this.state.SaleType == 1 ? <option value="4">total</option> : null}
                      {this.state.SaleType == 1 ? <option value="5">per m²</option> : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 control-label">Status</label>
                <div className="col-md-4">
                  <select id="Status" name="Status" className="form-control" value={this.state.Status} onChange={this.handleStatusChange}>
                    <option value="1">Active</option>
                    <option value="2">Expired</option>
                    <option value="3">Withdrawn</option>
                    <option value="4">Rented</option>
                    <option value="5">Sold - Under Offer</option>
                    <option value="6">Sold - Registered</option>
                    <option value="7">Sold - Cancelled</option>
                    <option value="8">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="col-md-12 col-sm-12">
            <OMSTabBox id="mandateTabs" value={tabContent} setActiveTabId={this.setActiveTabId}></OMSTabBox>
            <div className="tab-content">
              <div id="mandate-contract" className={this.state.activeTabID === "mandate-contract" ? "tab-pane fade in active" : "tab-pane fade"}>
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                    <br />
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label className="col-md-4 control-label">Mandate</label>
                        <div className="col-md-8">
                          <OMSDropdown
                            id="MandateType"
                            name="MandateType"
                            options={this.props.MandateTypes}
                            valueField=""
                            labelField=""
                            value={this.state.MandateType}
                            onChange={this.handleDropdownChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4 control-label">{this.state.SaleType == "3" ? "Listing date" : "Mandate start"}</label>
                        <div className="col-md-8">
                          <div className="input-group">
                            <DatePicker
                              selected={this.state.StartDateDT}
                              onChange={(date) =>
                                this.setState({
                                  StartDate: FromJavascriptDateToDateString(date),
                                  StartDateDT: date,
                                })
                              }
                              dateFormat="dd-MM-yyyy"
                              value={this.state.StartDateDT}
                              name="StartDate"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              //style={{ width: 150 }}
                              className="form-control MandateDatePicker"
                            />
                            <span className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4 control-label">{this.state.SaleType == "3" ? "Auction date" : "Mandate end"}</label>
                        <div className="col-md-8">
                          <div className="input-group">
                            <DatePicker
                              selected={this.state.ExpiryDateDT}
                              onChange={(date) =>
                                this.setState({
                                  ExpiryDate: FromJavascriptDateToDateString(date),
                                  ExpiryDateDT: date,
                                })
                              }
                              dateFormat="dd-MM-yyyy"
                              value={this.state.ExpiryDateDT}
                              name="ExpiryDate"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              //style={{ width: 150 }}
                              className="form-control MandateDatePicker"
                            />
                            <span className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4 control-label">Occupation date</label>
                        <div className="col-md-8">
                          <div className="input-group">
                            <DatePicker
                              selected={this.state.OccupationDateDT}
                              onChange={(date) =>
                                this.setState({
                                  OccupationDateDT: date,
                                  OccupationDate: FromJavascriptDateToDateString(date),
                                })
                              }
                              dateFormat="dd-MM-yyyy"
                              value={this.state.OccupationDateDT}
                              name="OccupationDate"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              //style={{ width: 150 }}
                              className="form-control MandateDatePicker2"
                            />
                            <span className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div id="picker-container" style={{ position: "relative" }}></div>
                      <div>
                        <div
                          className="form-group"
                          style={{
                            display: this.state.SaleType == 2 ? "" : "none",
                          }}
                        >
                          <label className="col-md-4 control-label">Lease escalation %</label>
                          <div className="col-md-8">
                            <OMSNumericBox
                              id="LeaseEscalationPer"
                              name="LeaseEscalationPer"
                              separator=""
                              maxLength={5}
                              value={this.state.LeaseEscalationPer}
                              isDecimal={true}
                              onChange={this.handleNumericChange}
                            />
                          </div>
                        </div>
                        <div
                          className="form-group"
                          style={{
                            display: this.state.SaleType == 2 ? "" : "none",
                          }}
                        >
                          <label className="col-md-4 control-label">Lease period</label>
                          <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              id="LeasePeriod"
                              name="LeasePeriod"
                              placeholder="Lease period"
                              value={this.state.LeasePeriod}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div
                          className="form-group"
                          style={{
                            display: this.state.SaleType == 2 ? "" : "none",
                          }}
                        >
                          <label className="col-md-4 control-label">Deposit requirements</label>
                          <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              id="DepositRequirement"
                              name="DepositRequirement"
                              placeholder="Deposit requirements"
                              value={this.state.DepositRequirement}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="mandate-commission" className={this.state.activeTabID === "mandate-commission" ? "tab-pane fade in active" : "tab-pane fade"}>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <br />
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label className="col-md-2 control-label" style={{ paddingTop: 0 }}>
                          Commission
                        </label>
                        <div className="col-md-2">
                          <label>%</label>
                        </div>
                        <div className="col-md-8">
                          <label>Amount</label>
                        </div>
                      </div>
                      <div className="form-group">
                        <span className="col-md-2 control-label">Nett</span>
                        <div className="col-md-2">
                          <OMSNumericBox
                            id="CommPerc"
                            name="CommPerc"
                            maxLength={6}
                            style={{ width: 100 }}
                            value={this.state.CommPerc}
                            isDecimal={true}
                            onChange={this.handleCommPercChange}
                            readOnly={this.state.CommType == "2"}
                          />
                        </div>
                        <div className="col-md-8 form-inline">
                          <OMSNumericBox
                            id="CommAmount"
                            separator=","
                            name="CommAmount"
                            maxLength={10}
                            style={{ width: 150 }}
                            value={this.state.CommAmount}
                            isDecimal={true}
                            onChange={this.handleCommAmountChange}
                            readOnly={this.state.CommType == "1"}
                          />

                          <div className="btn-group" style={{ marginLeft: 25 }}>
                            <button
                              className={this.state.CommType == "1" ? "btn btn-success-oms" : "btn btn-default"}
                              onClick={() => this.handleCommTypeChange("1")}
                            >
                              %
                            </button>
                            <button
                              href="#"
                              className={this.state.CommType == "2" ? "btn btn-success-oms" : "btn btn-default"}
                              onClick={() => this.handleCommTypeChange("2")}
                            >
                              Fixed
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <span className="col-md-2 control-label">VAT</span>
                        <div className="col-md-2">
                          <OMSNumericBox
                            id="Tax"
                            name="Tax"
                            className="form-control"
                            maxLength={10}
                            style={{ width: 100 }}
                            value={this.state.Tax}
                            isDecimal={true}
                            onChange={this.handleVATChange}
                          />
                        </div>
                        <div className="col-md-8">
                          <OMSNumericBox
                            id="VATAmount"
                            separator=","
                            name="VATAmount"
                            maxLength={10}
                            style={{ width: 150 }}
                            value={this.state.VATAmount}
                            isDecimal={true}
                            onChange={this.handleNumericChange}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <span className="col-md-2 control-label">Gross</span>
                        <div className="col-md-2">
                          <OMSNumericBox
                            id="Gross"
                            name="Gross"
                            className="form-control"
                            maxLength={10}
                            style={{ width: 100 }}
                            value={this.state.Gross}
                            isDecimal={true}
                            readOnly={true}
                            onChange={this.handleVATChange}
                          />
                        </div>
                        <div className="col-md-8">
                          <OMSNumericBox
                            id="GrossAmount"
                            separator=","
                            name="GrossAmount"
                            maxLength={10}
                            style={{ width: 150 }}
                            value={this.state.GrossAmount}
                            isDecimal={true}
                            onChange={this.handleNumericChange}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <span className="col-md-2 control-label">Owner nett</span>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <OMSNumericBox
                            id="OwnerNett"
                            separator=","
                            name="OwnerNett"
                            maxLength={10}
                            style={{ width: 150 }}
                            value={this.state.OwnerNett}
                            isDecimal={true}
                            onChange={this.handleNumericChange}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="mandate-itemisedcost" className={this.state.activeTabID === "mandate-itemisedcost" ? "tab-pane fade in active" : "tab-pane fade"}>
                <div className="row">
                  <br />
                  <div className="col-md-12 hidden-sm hidden-xs">
                    <div className="form-inline form-group" style={{ float: "right" }}>
                      <OMSDropdown
                        id="ItemisedUnits"
                        style={{ width: "auto" }}
                        name="ItemisedUnits"
                        options={this.state.ItemisedCostsItemDD}
                        valueField="Value"
                        labelField="Text"
                        value={this.state.ItemisedUnits}
                        onChange={this.handleItemisedCostDropdownChange}
                      />
                      {this.props.save_access ? (
                        <button
                          style={{ marginLeft: 10 }}
                          className={this.state.ItemisedUnits ? "btn btn-success-oms" : "btn btn-success-oms disabled"}
                          onClick={this.onAddItemisedCostUnitClick}
                        >
                          Add
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 hidden-md hidden-lg">
                    <OMSDropdown
                      id="ItemisedUnits"
                      style={{ width: "auto" }}
                      name="ItemisedUnits"
                      options={this.state.ItemisedCostsItemDD}
                      valueField="Value"
                      labelField="Text"
                      value={this.state.ItemisedUnits}
                      onChange={this.handleItemisedCostDropdownChange}
                    />
                    <button
                      style={{ marginTop: 5 }}
                      className={this.state.ItemisedUnits ? "btn btn-success-oms" : "btn btn-success-oms disabled"}
                      onClick={this.onAddItemisedCostUnitClick}
                    >
                      Add
                    </button>
                  </div>

                  <div className="col-md-12 col-sm-12">
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label className="col-md-3 control-label" style={{ paddingTop: 0 }}></label>
                        <div className="col-md-3">
                          <label>Size or Units</label>
                        </div>
                        <div className="col-md-6">
                          <label>Net Cost</label>
                        </div>
                      </div>
                      {this.state.ItemisedCosts.map(function (item, index) {
                        //console.log("inside this.state.ItemisedCosts.map, item=",item);
                        var disabledInput = item.itemName == "Installation allowance" || item.itemName == "Operational cost";
                        return (
                          <div key={index} className="form-group">
                            <label className="col-md-3 control-label">{item.itemName}</label>
                            <div className="col-md-3">
                              {disabledInput ? (
                                <input
                                  type="text"
                                  disabled="true"
                                  className="form-control"
                                  id={"SizeOrUnits_" + item.itemName}
                                  name={"SizeOrUnits_" + item.itemName}
                                />
                              ) : (
                                <OMSNumericBox
                                  id={"SizeOrUnits_" + item.itemName}
                                  name={"SizeOrUnits_" + item.itemName}
                                  maxLength={20}
                                  value={item.sizeOrUnits}
                                  isDecimal={true}
                                  onChange={self.handleItemisedCostSizeOrUnitChange}
                                />
                              )}
                            </div>
                            <div className="col-md-3">
                              <OMSNumericBox
                                id={"Cost_" + item.itemName}
                                name={"Cost_" + item.itemName}
                                maxLength={20}
                                isDecimal={true}
                                value={item.cost}
                                onChange={self.handleItemisedCostCostChange}
                              />
                            </div>
                            <div className="col-md-3 col-sm-12 form-inline">
                              <select
                                className="form-control"
                                style={{ width: 120, display: "inline-block" }}
                                value={item.unitType}
                                id={"UnitType_" + item.itemName}
                                name={"UnitType_" + item.itemName}
                                onChange={self.handleItemisedCostUnitTypeChange}
                              >
                                <option value="1">per m²</option>
                                <option value="2">per unit</option>
                              </select>

                              <span
                                style={{
                                  marginLeft: 10,
                                  cursor: "pointer",
                                  display: "inline-block",
                                }}
                                onClick={() => self.onRemoveItemisedCostUnitClick(item.itemName)}
                              >
                                <i style={{ fontSize: 25 }} className="fa fa-times-circle" aria-hidden="true"></i>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div id="mandate-documents" className={this.state.activeTabID === "mandate-documents" ? "tab-pane fade in active" : "tab-pane fade"}>
                <div className="row">
                  <DocumentFileComponent
                    ref={this.documentComponentRef}
                    save_access={this.props.save_access}
                    data={this.state.Documents}
                    onChange={this.onFileComponentChange}
                    officeSyncID={this.props.officeSyncID}
                    propertyID={this.props.data.propertyID}
                    onUpload={this.handleDocumentUpload}
                  />
                </div>
              </div>
              <div
                id="mandate-deals"
                className={this.state.activeTabID === "mandate-deals" ? "tab-pane fade in active" : "tab-pane fade"}
                style={{ minHeight: "100px" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    {this.props.save_access && (
                      <div className="pull-right">
                        <button type="button" onClick={() => this.redirectToDeal("newDeal", this.props.data.propertyID)} className="btn btn-success-oms">
                          New Deal
                        </button>
                      </div>
                    )}

                    <div style={{ marginTop: "45px" }}>
                      {this.state.isDealLoading ? (
                        <AjaxLoader />
                      ) : (
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: "10%" }}>Deal Ref</th>
                              <th style={{ width: "20%" }}>Deal Name</th>
                              <th style={{ width: "30%" }}>Progress</th>
                              <th style={{ width: "15%", minWidth: "25px" }}>Offer Price</th>
                              <th style={{ width: "15%", minWidth: "25px" }}>Expected Registration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dealData?.map((item, i) => {
                              return [
                                <tr key={i}>
                                  <td>
                                    {/* <a href={`/deal/saveDeal?ID=${item.dealID}&propertyID=${item.propertyID}`}>{item.dealRef}</a> */}
                                    <Link to={"/deal/saveDeal?ID=" + item.dealID + "&propertyID=" + item.propertyID}>{item.dealRef}</Link>
                                  </td>
                                  <td>
                                    <span>{item.dealName}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {item.pipeline}: {item.dealStage}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{`${this.props.CurrencyDisplay}${ToPriceString(item.purchasePrice)}`}</span>
                                  </td>
                                  <td>
                                    <span>{item.expectedRegistrationDate}</span>
                                  </td>
                                </tr>,
                              ];
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* mandate-models */}
              <ConfirmationModalPopup {...this.state.confirmationModalProps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
